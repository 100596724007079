class AccordionFilterSection {
    accordion: Element;

    constructor(accordion: Element) {
        this.accordion = accordion;

        const contentDivsMobile = accordion.querySelectorAll('.click-div-accordion-mobile') as NodeListOf<HTMLElement>;

        if (contentDivsMobile.length) {
            contentDivsMobile.forEach((contentDiv) => {
                contentDiv.addEventListener('click', (event) => {
                    event.stopPropagation();
                    this.setMobileActive(contentDiv);
                });
            });
        }

        // desktop accordion

        const clickDivs = accordion.querySelectorAll('.click-div-tab') as NodeListOf<HTMLElement>;

        clickDivs.forEach((clickDiv) => {
            clickDiv.addEventListener('click', (event) => {
                if (clickDiv.tagName === 'A') {
                    event.preventDefault();
                    const targetId = (event.currentTarget as HTMLAnchorElement).getAttribute('href')?.substring(1);
                    if (targetId) {
                        const targetElement = document.getElementById(targetId);
                        if (targetElement) {
                            this.setActive(clickDiv, true);
                            if (history.pushState) {
                                history.pushState(null, '', `#${targetId}`);
                            } else {
                                location.hash = `#${targetId}`;
                            }
                        }
                    }
                } else {
                    this.setActive(clickDiv, true);
                }
            });
        });

        let divWithHash, mobileDivWithHash;

        const mediaQuery = window.matchMedia('(max-width: 767px)');

        if (window.location.hash) {
            if (!mediaQuery.matches) {
                divWithHash = Array.from(clickDivs).find((clickDiv) => {
                    return clickDiv.id === window.location.hash.replace('#', '');
                });

                if (divWithHash) {
                    this.setActive(divWithHash, true);
                }
            }

            if (mediaQuery.matches) {
                mobileDivWithHash = Array.from(contentDivsMobile).find((clickDiv) => {
                    return clickDiv.querySelector('a')?.id === window.location.hash.replace('#', '');
                });

                if (mobileDivWithHash) {
                    this.setMobileActive(mobileDivWithHash);
                }
            }
        }

        if (clickDivs.length > 0 && !divWithHash) {
            this.setActive(clickDivs[0], false);
        }
    }

    setMobileActive(contentDiv: HTMLElement) {
        const activeContentDiv = this.accordion.querySelector('.click-div-accordion-mobile.active');
        const arrowDown = contentDiv.querySelector('.accordion-arrow-mobile');
        if (!arrowDown) {
            return;
        }

        if (activeContentDiv && activeContentDiv != contentDiv) {
            activeContentDiv.classList.remove('active');
            const activeHiddenDivMobile = activeContentDiv.querySelector('.hidden-content-div-mobile');
            if (activeHiddenDivMobile) {
                activeHiddenDivMobile.setAttribute('style', 'maxHeight:0 !important;');
            }
            const activeArrowDown = activeContentDiv.querySelector('.accordion-arrow-mobile');
            if (activeArrowDown) {
                activeArrowDown.classList.add('rotated');
            }
        }

        const textDivMobile = contentDiv.querySelector('.hidden-content-div-mobile') as HTMLElement;
        if (contentDiv.classList.contains('active')) {
            contentDiv.classList.remove('active');
            arrowDown.classList.add('rotated');
            if (textDivMobile) {
                textDivMobile.setAttribute('style', 'maxHeight:0 !important;');
            }
        } else {
            contentDiv.classList.add('active');
            if (textDivMobile) {
                textDivMobile.setAttribute('style', '');
            }
            arrowDown.classList.remove('rotated');
        }
    }

    setActive(clickDiv: HTMLElement, scrollTo: boolean) {
        // get index of clicked tab
        const targetIndex = clickDiv.dataset.targetIndex;
        if (!targetIndex) {
            return;
        }

        // get title related to clicked tab
        const currentTitle = this.accordion.querySelector('[data-target-index="' + targetIndex + '"]');
        const allTabTitles = this.accordion.querySelectorAll('.click-div-tab');

        // remove active from all tab titles
        allTabTitles.forEach(function (tabTitle) {
            tabTitle.classList.remove('active');
        });

        // add active to clicked tab title
        if (currentTitle) {
            currentTitle.classList.add('active');
        }

        // get tab content related to clicked tab title
        const currentTabContent = this.accordion.querySelector('[data-content-index="' + targetIndex + '"]');
        const allContentBlocks = this.accordion.querySelectorAll('.accordion-tab-content');
        allContentBlocks.forEach(function (content) {
            content.classList.add('hidden');
        });

        if (currentTabContent) {
            currentTabContent.classList.remove('hidden');

            if (scrollTo) {
                const targetPosition = currentTabContent.getBoundingClientRect().top + window.scrollY;

                setTimeout(() => {
                    this.scrollToWithCallback({ top: targetPosition - 180, behavior: 'smooth' });
                }, 100);
            }
        }
    }

    scrollToWithCallback(options: ScrollToOptions) {
        window.scrollTo(options);
    }
}

export default {
    init() {
        const accordionFilterSections = document.querySelectorAll('.accordion-filter-sections') as NodeListOf<Element>;

        if (!accordionFilterSections.length) {
            return;
        }

        accordionFilterSections.forEach((accordion) => {
            new AccordionFilterSection(accordion);
        });
    }
};
